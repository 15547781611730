import { useMediaQuery } from 'react-responsive';

export const breakPoints = {
  desktop: '992',
  tablet: '768',
  phone: '576',
  xs: '375',
  sm: '640',
  md: '768',
  lg: '1024',
  xl: '1280',
  xxl: '1440'
};

const useMedia = () => {
  const isMobileXs = useMediaQuery({ maxWidth: +breakPoints.xs });
  const isMobile = useMediaQuery({ maxWidth: +breakPoints.sm });
  const isTablet = useMediaQuery({  minWidth: +breakPoints.sm + 1, maxWidth: +breakPoints.lg });
  const isTabletPortrait = useMediaQuery({  minWidth: +breakPoints.sm + 1, maxWidth: +breakPoints.md });
  const isTabletLandscape = useMediaQuery({  minWidth: +breakPoints.md + 1, maxWidth: +breakPoints.lg });
  const isDesktop = useMediaQuery({ minWidth: +breakPoints.lg +1 });


  const isHeightXs = useMediaQuery({ maxHeight: +breakPoints.sm });
  const isHeightMd = useMediaQuery({ maxHeight: +breakPoints.md });

  return { isMobileXs, isMobile, isTablet, isTabletPortrait, isTabletLandscape, isDesktop, isHeightXs, isHeightMd }
}

export default useMedia;