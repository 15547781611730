import React, {useState} from 'react';

import Carousel from 'nuka-carousel';
import styled from 'styled-components';

const ArticleCarousel = (props) => {

  return <CustomCarrousel
    style={{ outline: 'none', paddingBottom: '1rem' }}
    slideWidth={0.75}
    cellSpacing={60}
    cellAlign={'center'}
    enableKeyboardControls
    renderCenterLeftControls={({ previousSlide }) => <ChangeSlide onClick={previousSlide} />}
    renderCenterRightControls={({ nextSlide }) => <ChangeSlide onClick={nextSlide} />}
  >
  {props.children}
  </CustomCarrousel>

}

export default ArticleCarousel;

const CustomCarrousel = styled(Carousel)`
  & .slider-slide{
    margin: auto 0 !important;
  }
`;

const ChangeSlide = styled.div`
  width: 100px;
  height: 500px;

  @media screen and (min-width: 769px) {
    width: 200px;
    height: 700px;
  }
`;


