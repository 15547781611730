import { Link } from 'gatsby';
import OverlayBg from '../../img/overlay-video.png';
import React from 'react';
import kwBg from '../../img/knowledge-hub-header-bg.jpg';
import logo from '../../img/logo.svg';
import styled from 'styled-components';

const KnowledgeHubHeader = () => {
  return (
    <HeaderWrapper>
      <Header>
        <nav>
          <NavContainer>
            <Link to="/">
              <img src={logo} alt="FilmChain" className="filmchain-icon" />
            </Link>
            <Link to="/login" className={`level-item btn inverted`}>
              {' '}
              Log in
            </Link>
          </NavContainer>
        </nav>
        <Title>Knowledge Hub</Title>
        <Subtitle>The place where you can read, see, hear all things FilmChain</Subtitle>
      </Header>
      <Overlay />
    </HeaderWrapper>
  );
};

export default KnowledgeHubHeader;

const HeaderWrapper = styled.div`
  position: relative;
  height: 50vh;
  background-image: url(${kwBg});
  background-size: cover;
  background-position: center;
`;

const Header = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 2;
`;

const Overlay = styled.div`
  background-image: url(${OverlayBg});
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-position: bottom;
  z-index: 1;
  background-repeat: repeat-x;
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1580px;
  margin: 0 auto;
  padding: 32px 20px 20px;

  @media screen and (min-width: 769px) {
    padding: 35px 5%;
  }
`;

const Title = styled.h1`
  font-size: 72px;
  line-height: 88px;
  text-align: center;
  color: white;
  padding: 0 20px;

  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);

  @media screen and (max-width: 769px) {
    font-size: 36px;
    line-height: 50px;
    text-align: left;
    top: inherit;
    bottom: 15%;
  }
`;

const Subtitle = styled.div`
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: white;
  opacity: 0.8;
  padding: 0 20px;

  position: absolute;
  top: calc(50% + 60px);
  left: 0;
  right: 0;
  transform: translateY(-50%);

  @media screen and (max-width: 769px) {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    top: inherit;
    bottom: 5%;
  }
`;
