import styled, {css} from 'styled-components';

import Img from 'gatsby-image';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

const Article = (props) => {

  const {
    contentful_id,
    createdAt,
    cover,
    date,
    featuredStory,
    title,
    description,
    url
  } = props.data;

  const openArticle = (url) => {
    let win = window.open(url, '_blank', 'noopener,noreferrer');
    win.focus();
  }

  return (
    <Container>
      <div onClick={() => openArticle(url)}><Cover fluid={cover.fluid}  isfeaturedStory={featuredStory} /></div>
      <Date>{moment(date).format('MMM D, YYYY')}</Date>
      <Title onClick={() => openArticle(url)} isfeaturedStory={featuredStory}>{title}</Title>
      <Description dangerouslySetInnerHTML={ { __html: description.childMarkdownRemark.html } }></Description>
    </Container>
  );
}

export default Article;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Date = styled.div`
  font-size: 0.875rem;
  line-height: 1rem;
  color: #a7a6b3;
  margin: 1.5rem 0 1rem;
`;

const Title = styled.div`
  margin-bottom: 1rem;
  color: white;
  overflow: auto;
  cursor: pointer;
  
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  
  &::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
  }

  ${props => props.isfeaturedStory ? 
    css`
      font-size: 1.5rem;
      line-height: 2rem;

      @media screen and (max-width: 769px) {
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
    `: css`
      font-size: 1.25rem;
      line-height: 1.75rem;
    `
  };
`;

const Description = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: white;
  overflow: auto;
  margin-bottom: 1rem;
  opacity: 0.7;

  @media screen and (max-width: 769px) {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;

const Cover = styled(Img)`
  cursor: pointer;
  ${props => props.isfeaturedStory ? 
    css`
      max-height:  370px;
      height:  370px;
      @media screen and (min-width: 769px) {
        max-height:  600px;
        height:  600px;
      }
      
    `: css`
      max-height:  200px;
      height:  200px;
    `
  };
`;