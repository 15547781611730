import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import Article from '../components/knowledge-hub/Article';
import Carrousel from '../components/knowledge-hub/Carousel';
import Grid from '../components/Grid';
import KnowledgeHubHeader from '../components/knowledge-hub/Header';
import Layout from '../components/Layout';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import useMediaQuery from '../components/knowledge-hub/useMediaQuery';

const elementsPerPage = 8;

const KnowledgeHub = ({ data }) => {

  const { isDesktop } = useMediaQuery();
  const [showDesktopLayout, setDesktopLayout] = useState(true);

  const [articlesToShow, setArticlesToShow] = useState([]);
  const [next, setNext] = useState(elementsPerPage);

  const allNodes = data.allContentfulArticle.nodes;
  const articles = allNodes.filter(article => !article.featuredStory).sort((a, b) => new Date(a.date) > new Date(b.date) ? -1 : 1);
  const featuredStories = allNodes.filter(article => article.featuredStory).sort((a, b) => new Date(a.date) > new Date(b.date) ? -1 : 1);

  const loopWithSlice = (start, end) => {
    const slicedArticles = articles.slice(start, end);
    setArticlesToShow(slicedArticles);
  };

  const handleShowMoreArticles = () => {
    loopWithSlice(0, next + elementsPerPage);
    setNext(next + elementsPerPage);
  };


  useEffect(() => {
    loopWithSlice(0, next);
    setDesktopLayout(isDesktop);
  }, [isDesktop]);

  const getGridColumnWidth = () => {
    if (isDesktop)
      return '285px';

    return '220px';
  }

  const gridColumnMinWidth = getGridColumnWidth();


  const renderCarouselOrGrid = () => {
    if (showDesktopLayout)
      return <FeaturesContainer>
        <Grid gridColumnMinWidth={gridColumnMinWidth} gridRowGap="40px" gridColumnGap={isDesktop ? "30px" : "20px"}>
          {featuredStories.map(article =>
            <Article data={article} key={article.contentful_id} />
          )}
        </Grid>
      </FeaturesContainer>;

    return <Carrousel>
      {featuredStories.map(article =>
        <Article data={article} key={article.contentful_id} />
      )}
    </Carrousel>;
  }

  return (
    <Layout>
      <Container>
        <KnowledgeHubHeader />
        <FeaturesContainer>
          <Divider />
          <SectionTitle>Featured Stories</SectionTitle>
        </FeaturesContainer>
        {renderCarouselOrGrid()}
        <ArticlesContainer>
          <Divider />
          <SectionTitle>All Articles</SectionTitle>
          <Grid gridColumnMinWidth={gridColumnMinWidth} gridRowGap="40px" gridColumnGap={isDesktop ? "30px" : "20px"}>
            {articlesToShow.map(article =>
              <Article data={article} key={article.contentful_id} />
            )}
          </Grid>
          {articlesToShow.length !== articles.length ? <LoadMore onClick={handleShowMoreArticles} className={`knowledge-hub level-item btn inverted`}>Load more articles</LoadMore> : null}
        </ArticlesContainer>
      </Container>
    </Layout>
  );
}

export default KnowledgeHub;

KnowledgeHub.propTypes = {
  data: PropTypes.shape({
    allContentfulArticle: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};

export const pageQuery = graphql`
  query KnowledgeHubQuery {
    allContentfulArticle {
      nodes {
        contentful_id
        createdAt
        cover {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        date
        featuredStory
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        url
      }
    }
  }
`;



const Container = styled.div`
  position: relative;
  background-color: #110d12;
  min-height: 100vh;
`;

const FeaturesContainer = styled.div`
  position: relative;
  max-width: 1580px;
  margin: 0 auto;
  padding: 0 20px;

  @media screen and (min-width: 769px) {
    padding: 0 5%;
  }
`;

const ArticlesContainer = styled.div`
  position: relative;
  max-width: 1580px;
  margin: 0 auto;
  padding: 40px 20px 80px;

  @media screen and (min-width: 769px) {
    padding: 40px 5% 80px;
  }
`;

const Divider = styled.div`
    background-color: #d8d8e3;
    opacity: 0.3;
    margin: 1rem auto;
    width: 100%;
    height: 2px;
    grid-column: 1/-1;
`;

const SectionTitle = styled.div`
  font-size: 36px;
  line-height: 50px;
  color: white;
  margin-bottom: 3.75rem;

  @media screen and (max-width: 769px) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 1.5rem;
  }
`;

const LoadMore = styled.div`
  margin: 0 auto;
`;