import PropTypes from 'prop-types';
import styled from 'styled-components';

const Grid = styled.div`
  grid-gap: ${(props) => props.gridGap};
  grid-column-gap: ${(props) => props.gridColumnGap};
  grid-row-gap: ${(props) => props.gridRowGap};
  display: grid;
  justify-items: ${(props) => props.gridJustifyItems};
  grid-template-columns: repeat(auto-fit, minmax(${(props) => props.gridColumnMinWidth}, 1fr));
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  grid-template-rows: ${(props) => props.gridTemplateRows};
  align-items: ${(props) => props.gridAlignItems};
  justify-content: ${(props) => props.gridJustifyContent};
  height: ${props => props.height && props.height};
  width: ${props => props.width && props.width || '100%'};
  margin-bottom: ${props => props.marginBottom && props.marginBottom};
  margin-top: ${props => props.marginTop && props.marginTop};
`;

Grid.propTypes = {
  gridGap: PropTypes.string,
  gridColumnGap: PropTypes.string,
  gridRowGap: PropTypes.string,
  gridJustifyItems: PropTypes.string,
  gridColumnMinWidth: PropTypes.string,
  gridAlignItems: PropTypes.string,
};

Grid.defaultProps = {
  gridGap: '',
  gridColumnGap: '',
  gridRowGap: '',
  gridJustifyItems: 'center',
  gridColumnMinWidth: '196px',
  gridAlignItems: 'start',
  gridTemplateColumns: '',
  gridJustifyContent: "center",
  
};

export default Grid;